$sidebar-bg-color: #fff !default;
$sidebar-color: #4d4d4d !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #383838 !default;
$submenu-bg-color: #dadada !default;
$submenu-bg-color-collapsed: #dadada !default;
$icon-bg-color: #dadada !default;
$icon-size: 35px !default;
$submenu-indent: 24px !default;

$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;
